import { data } from "jquery";
import { BaseUrl } from "network";
import toast from "react-hot-toast";
import { api } from "../../services";

export const editcustomersList = (data) => {
    console.log("dataaaaaaaaaaaaaa",data)
    return (dispatch) => {
        dispatch({
            type: "FETCH_CUSTOMERS_LIST",
        })
        api('POST', BaseUrl + `/partner/Customer/`,data).then((res) => {
            console.log("for edit data", res.data)
            
        }).catch((err) => {
            dispatch({
                type: "ERROR_EDITCUSTOMERS_LIST",
            })
            console.log("ERROR_EDITCUSTOMERS_LIST", err);
            toast.error("Error!! ", err, {
                position: "top-right",
            });
        })
    }
}