import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Card, CardHeader, Container, Row } from "reactstrap";
import { GET } from "network";
import { orderProductList } from "network";
import { HttpCallGetWithoutKey } from "Apis";
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ProductListSummary from "./ProductListSummary";
import { ContainerLoader } from "components/Loaders/Loader";
export default function AddProductForOrder(props) {
  const [stndServiceListData, setStndServiceListData] = useState([]);
  const [bndlServiceListData, setBndlServiceListData] = useState([]);
  const [reviewVisible, setReviewVisible] = useState(false);
  const [activateMainPlan, setActivateMainPlan] = useState("bndl");
  const [loader, setLoader] = useState(false);
  const [productInfo, setProductInfo] = useState({ idList: [] });
  const [extraServiceDeliveryInfo, setExtraServiceDeliveryInfo] = useState({
    idList: [],
  });
  const [extraServiceAdditionalInfo, setExtraServiceAdditionalInfo] = useState({
    idList: [],
  });

  const toggleHandler = (e, type) => {
    const { value, checked } = e.target;
    const { idList } = productInfo;
    console.log(`${value} is ${checked}`);

    if (type == "bndl") {
      // let checkboxes = document.getElementsByName("BidList");
      // checkboxes.forEach((item) => {
      //   item.value !== value ? (item.checked = false) : (item.checked = true);
      // });
      if (checked) {
        setProductInfo({
          idList: [value],
        });
      } else {
        setProductInfo({
          idList: idList.filter((e) => e !== value),
        });
      }
    } else if (type == "stnd") {
      if (checked == false) {
        setExtraServiceAdditionalInfo({
          idList: extraServiceAdditionalInfo.idList.filter((e) => e !== value),
        });
        setExtraServiceDeliveryInfo({
          idList: extraServiceDeliveryInfo.idList.filter((e) => e !== value),
        });
      }
      // Case 1 : The user checks the box
      if (checked) {
        setProductInfo({
          idList: [...idList, value],
        });
      }

      // Case 2  : The user unchecks the box
      else {
        setProductInfo({
          idList: idList.filter((e) => e !== value),
        });
      }
    }
  };

  const extraServiceHandler = (e, type) => {
    const { value, checked } = e.target;

    console.log(`${value} is ${checked}`);

    if (productInfo.idList.some((a) => a == value) == true) {
      if (type == "delivery") {
        const { idList } = extraServiceDeliveryInfo;
        // Case 1 : The user checks the box
        if (checked) {
          setExtraServiceDeliveryInfo({
            idList: [...idList, value],
          });
        }

        // Case 2  : The user unchecks the box
        else {
          setExtraServiceDeliveryInfo({
            idList: idList.filter((e) => e !== value),
          });
        }
      }

      if (type == "additional") {
        const { idList } = extraServiceAdditionalInfo;
        // Case 1 : The user checks the box
        if (checked) {
          setExtraServiceAdditionalInfo({
            idList: [...idList, value],
          });
        }

        // Case 2  : The user unchecks the box
        else {
          setExtraServiceAdditionalInfo({
            idList: idList.filter((e) => e !== value),
          });
        }
      }
    }
  };

  useEffect(() => Data(), []);
  const Data = async () => {
    // props.draftOrderId||
    HttpCallGetWithoutKey(
      orderProductList + props.draftOrderId,
      GET,
      localStorage.getItem("Token")
    )
      .then((res) => {
        console.log("aaa", res);
        setStndServiceListData(
          res?.data.filter((m) => m.type == "STND").map((data) => data)
        );
        setBndlServiceListData(
          res?.data.filter((m) => m.type == "BNDL").map((data) => data)
        );
      })
      .catch((err) => {
        console.log("error in table", err);
        toast.error("Error!! ", err?.data?.msg, {
          position: "top-right",
        });
      });
  };

  return (
    <>
      {" "}
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h3 className="mb-0">Select Services</h3>
                <div className="flex select-main-service">
                  <div className="flexn flex-center-center">
                    <input
                      type="radio"
                      value="bndl"
                      id="bndl"
                      style={{ padding: "0 4px" }}
                      checked={activateMainPlan === "bndl"}
                      onClick={(e) => {
                        setActivateMainPlan("bndl");
                        setProductInfo({ idList: [] });
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "10px" }}>
                    <label htmlFor="bndl" style={{ padding: "0 5px" }}>
                      Bundle
                    </label>
                  </div>
                  <div className="flexn flex-center-center">
                    <input
                      type="radio"
                      value="stnd"
                      id="stnd"
                      style={{ padding: "0 4px" }}
                      onClick={(e) => {
                        setActivateMainPlan("stnd");
                        setProductInfo({ idList: [] });
                      }}
                      checked={activateMainPlan === "stnd"}
                    />
                  </div>
                  <div className="flexn flex-center-center">
                    <label htmlFor="stnd" style={{ padding: "0 5px" }}>
                      Standard
                    </label>
                  </div>
                </div>
              </div>
            </CardHeader>
            {loader == true ? <ContainerLoader /> : ""}

            <Container fluid style={{ position: "relative" }} className="mb-8">
              {reviewVisible == false ? (
                <>
                  <div style={{ float: "left", width: "65%", minHeight:"370px" }}>
                    {/* <span
                  onClick={() => {
                    console.log(stndServiceListData);
                    console.log(productInfo);
                    console.log(extraServiceDeliveryInfo);
                    console.log(extraServiceAdditionalInfo);
                  }}
                >
                  abc
                </span> */}
                    {activateMainPlan == "bndl" ? (
                      <>
                        {bndlServiceListData.map((item, index) => (
                          <Accordion
                            style={{
                              // width: "65%",
                              padding: "10px",
                              margin: "5px 0",
                            }}
                            key={index}
                          >
                            <Typography
                              style={{
                                fontWeight: 10,
                                padding: "5px",
                                backgroundColor: "#3ab54a0a",
                              }}
                            >
                              <span style={{ display: "flex" }}>
                                <input
                                  type="checkbox"
                                  style={{ width: "10%", marginTop: "5px" }}
                                  name="BidList"
                                  value={item.id}
                                  onChange={(e) => {
                                    console.log(e)
                                    toggleHandler(e, "bndl");
                                  }}
                                  //checked={productInfo.idList>0 && productInfo.idList.includes(item.id)}
                                />
                                <span style={{ width: "60%" }}>
                                  {item.name}
                                </span>
                                <span
                                  style={{ width: "30%", textAlign: "right" }}
                                >{`${item.pricing?.currency} ${item.pricing?.price}`}</span>
                              </span>
                            </Typography>
                          </Accordion>
                        ))}
                      </>
                    ) : (
                      <>
                        {stndServiceListData.map((item, index) => (
                          <Accordion
                            style={{
                              // width: "65%",
                              padding: "10px",
                              margin: "5px 0",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                item.addons.length > 0 ? (
                                  <i
                                    className="fa fa-caret-down"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  ""
                                )
                              }
                              aria-controls="panel1a-content"
                              key={index}
                              style={{ backgroundColor: "#3ab54a0a" }}
                            >
                              <Typography
                                style={{
                                  fontWeight: 10,
                                  width: "100%",
                                }}
                              >
                                <span style={{ display: "flex" }}>
                                  <input
                                    type="checkbox"
                                    style={{ width: "10%", marginTop: "5px" }}
                                    name="idList"
                                    value={item.id}
                                    onChange={(e) => {
                                      toggleHandler(e, "stnd");
                                    }}
                                  />
                                  <span style={{ width: "60%" }}>
                                    {item.name}
                                  </span>
                                  <span
                                    style={{ width: "30%", textAlign: "right" }}
                                  >{`${item.pricing?.currency} ${item.pricing?.price}`}</span>
                                </span>
                                {item.pricing.extra_option > 0 ? (
                                  <span style={{ display: "flex" }}>
                                    <input
                                      type="checkbox"
                                      style={{ width: "10%", marginTop: "5px" }}
                                      onChange={(e) =>
                                        extraServiceHandler(e, "additional")
                                      }
                                      name="idList"
                                      value={item.id}
                                      disabled={
                                        !productInfo.idList.some(
                                          (a) => a == item.id
                                        )
                                      }
                                    />
                                    <span style={{ width: "60%" }}>
                                      Additional Options
                                    </span>
                                    <span
                                      style={{
                                        width: "30%",
                                        textAlign: "right",
                                      }}
                                    >{`${item.pricing?.currency} ${item.pricing?.extra_option}`}</span>
                                  </span>
                                ) : (
                                  ""
                                )}
                                {item.pricing.immediate_delivery > 0 ? (
                                  <span style={{ display: "flex" }}>
                                    <input
                                      type="checkbox"
                                      style={{ width: "10%", marginTop: "5px" }}
                                      name="idList"
                                      value={item.id}
                                      onChange={(e) =>
                                        extraServiceHandler(e, "delivery")
                                      }
                                      disabled={
                                        !productInfo.idList.some(
                                          (a) => a == item.id
                                        )
                                      }
                                    />
                                    <span style={{ width: "60%" }}>
                                      Instant Delivery
                                    </span>
                                    <span
                                      style={{
                                        width: "30%",
                                        textAlign: "right",
                                      }}
                                    >{`${item.pricing?.currency} ${item.pricing?.immediate_delivery}`}</span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Typography>
                            </AccordionSummary>
                            {item.addons.length > 0 ? (
                              <AccordionDetails>
                                <Typography
                                  style={{
                                    fontWeight: 10,
                                    width: "100%",
                                  }}
                                >
                                  {item.addons.map((row, i) => (
                                    <span
                                      style={{
                                        display: "flex",
                                        padding: "5px",
                                        borderTop: "2px solid #80808075",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        style={{
                                          width: "10%",
                                          marginTop: "5px",
                                        }}
                                        name="idList"
                                        value={row.id}
                                        onChange={(e) => {
                                          toggleHandler(e, "stnd");
                                        }}
                                        // checked={productInfo[row.id]}
                                      />
                                      <span style={{ width: "60%" }}>
                                        {row.name}
                                      </span>
                                      <span
                                        style={{
                                          width: "30%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {" "}
                                        {`${row.pricing.currency} ${row.pricing.price}`}
                                      </span>
                                    </span>
                                  ))}
                                </Typography>
                              </AccordionDetails>
                            ) : (
                              ""
                            )}
                          </Accordion>
                        ))}
                      </>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
              {productInfo.idList.length > 0 && (
                <div>
                  <ProductListSummary
                    stndServiceListData={stndServiceListData}
                    bndlServiceListData={bndlServiceListData}
                    productList={productInfo}
                    setReviewVisible={setReviewVisible}
                    diliveryServices={extraServiceDeliveryInfo}
                    additionalServices={extraServiceAdditionalInfo}
                    orderId={props.draftOrderId}
                    activateMainPlan={activateMainPlan}
                  />
                </div>
              )}
            </Container>
          </Card>
        </div>
      </Row>
    </>
  );
}
