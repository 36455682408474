import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import reducers from "../reducers";
import rootSaga from "../sagas";

//const sagaMiddleware = createSagaMiddleware();
const Store = createStore(
    reducers,
    {},
    applyMiddleware(thunk)
);

//sagaMiddleware.run(rootSaga)

export default Store;


