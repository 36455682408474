import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { Link, useHistory, withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import "../../../assets/css/argon-dashboard-react.css";
import toast from "react-hot-toast";
import { FaArrowCircleRight } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import { HttpCallPost, HttpCallPostToken } from "../../../Apis/index";
import { POST } from "network";
import { otpSendLink } from "network";
import { verifyOtpLink } from "network";
import styled from "styled-components";
import { ButtonLoader } from "components/Loaders/Loader";

const OtpBtn = styled.div`
  svg {
    cursor: pointer;
    &:hover {
      color: gray !important;
    }
  }
`;

const AddCustomerModel = (props) => {
  const [value, setValue] = useState(null);
  const [isd, setIsd] = useState(null);
  const [number, setNumber] = useState(null);
  let history = useHistory();
  const [showOtp, setShowOtp] = useState(false);
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState(false);
  const [isBtnDisabled, setBtnDisabled] = useState(false);

  const [requestID, setrequestID] = useState(null);

  useEffect(() => {
    if (props.type == "model") {
      setIsd(props?.selectedCustomer?.isdCode);
      setNumber(props?.selectedCustomer?.contact);
    }
  }, [props.selectedCustomer]);

  const handleOnChange = (value, data, event, formattedValue) => {
    setIsd(data.dialCode);
    setNumber(value);
  };

  const sendOtp = async () => {
    if (number.length > 5 ) {
      setShowOtp(true);
      var data = {
        mobile: number.length>10?number.slice(isd.length, number.length):number,
        isd: isd,
        // appcode: appcode
      };
      HttpCallPostToken(otpSendLink, POST, data, localStorage.getItem("Token"))
        .then((res) => {
          setrequestID(res.data.requestID);
          // requestID = res.data.requestID
          console.log("response 71 71", res.data.requestID);
          toast.success("Otp send to your Registerd Mobile Number!", {
            position: "top-right",
          });
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err.response.data.msg, {
            position: "top-right",
          });
          setShowOtp(false);
          setLoader(false);
          console.warn("hiiiiiiiii", err.response.data);
        });
    } else {
      setLoader(false);
      Swal.fire({
        position: "center",
        type: "error",
        title: "Please Enter Number",
      });
    }
  };

  const otpVerify = () => {
    console.log("108", requestID);
    if (otp.length > 3)  {
      var data = {
        mobile: number.length>10?number.slice(isd.length, number.length):number,
        isd: isd,
        otp: otp,
        // appcode: appcode,
        requestID: requestID,
      };
      console.log("data in 110", data);
      HttpCallPostToken(
        verifyOtpLink,
        POST,
        data,
        localStorage.getItem("Token")
      )
        .then((res) => {
          toast.success("Customer Added Successfully", {
            position: "top-right",
          });
          setBtnDisabled(false);
          history.push("/admin/customer-list");
          if (props.type == "model") {
            props.toggle();
          }
          console.log("response 71 71", res.data);
        })
        .catch((err) => {
          console.log("errrrr", err.response.data);
          setBtnDisabled(false);
          toast.error(err.response.data.msg, {
            position: "top-right",
          });
          // Swal.fire({
          //   position: "center",
          //   type: "error",
          //   title: err.response.data,
          // });
        });
    } else {
      setBtnDisabled(false);
      Swal.fire({
        position: "center",
        type: "error",
        title: "Please Enter OTP",
      });
    }
  };

  return (
    <>
      {" "}
      <Form role="form">
        <FormGroup className="mb-3"></FormGroup>
        <FormGroup>
          <label>Enter Customer Mobile No.</label>

          <InputGroup className="input-group-alternative textarea-addon">
            <InputGroupAddon>
              <InputGroupText>
                {props.type == "model" ? (
                  <PhoneInput
                    country={"in"}
                    value={
                      value ||
                      props?.selectedCustomer?.isdCode +
                        props?.selectedCustomer?.contact
                    }
                    enableAreaCodeStretch
                    buttonStyle={{ borderColor: "#fff" }}
                    inputStyle={{
                      left: 50,
                      maxWidth: window.innerWidth >= 540?"220px":"165px",
                      borderColor: "#fff",
                    }}
                    disabled={true}
                    onChange={handleOnChange}
                  />
                ) : (
                  <PhoneInput
                    country={"in"}
                    value={value}
                    enableAreaCodeStretch
                    buttonStyle={{ borderColor: "#fff" }}
                    inputStyle={{
                      left: 50,
                      maxWidth: window.innerWidth >= 540?"220px":"185px",
                      borderColor: "#fff",
                    }}
                    onChange={handleOnChange}
                  />
                )}
                <OtpBtn>
                  {loader == false ? (
                    <FaArrowCircleRight
                      color={"#000"}
                      size={28}
                      onClick={() => {
                        sendOtp();
                        setLoader(true);
                      }}
                    />
                  ) : (
                    <ButtonLoader />
                  )}
                </OtpBtn>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        {showOtp && (
          <>
            <FormGroup>
              <InputGroup className="input-group-alternative ">
                <InputGroupAddon
                // addonType="prepend"
                >
                  <InputGroupText>
                    {/* <i className="ni ni-lock-circle-open" /> */}
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="OTP"
                  type="number"
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                />
              </InputGroup>
            </FormGroup>
            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="button"
                onClick={() => {
                  setBtnDisabled(true);
                  otpVerify();
                }}
                disabled={isBtnDisabled}
              >
                {isBtnDisabled == true ? <ButtonLoader /> : "Add"}
              </Button>
            </div>{" "}
          </>
        )}
      </Form>
    </>
  );
};
export default AddCustomerModel;
