

import { combineReducers } from "redux";
import CustomersReducer from "./CustomersReducer";


const reducers = combineReducers({
    customers:CustomersReducer,
   
})

export default reducers