import { takeEvery, call, fork, put } from "redux-saga/effects";
import {api} from "../../services";
import actions from "../actions";

function* customersList(perm) {
    try{
        console.log('insaga')
        const result = yield call(api,'GET',`partner/customer?page=${perm.page}`)
        yield put(actions.recieveCustomersList(result.data))
    }catch(error){
        console.log(error)
        yield put(actions.failCustomersList())
    }
}

function* watchGetCustomersList(){
    yield takeEvery('FETCH_CUSTOMERS_LIST',customersList)
}

const customersListSagas = [
    fork(watchGetCustomersList)
];

export default customersListSagas;