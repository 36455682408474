import React from "react";
import { HttpCallGet } from "Apis";
import { useEffect, useState } from "react";
import { customerList } from "network";
import { GET } from "network";
import EmptyList from "../../assets/placeholders/emptylist.jpg";
import { ShimmerTable } from "react-shimmer-effects";
import toast from "react-hot-toast";

import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Pagination,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import GPagination from "components/Pagination/GPagination.js";
import { bindActionCreators } from "redux";
import actionCreators from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { GetCommissionList } from "network";
import { GetPartnerWalletBalance } from "network";
import WithdrawModel from "./WithdrawModel";
// core components

export default function ComissionList() {
  const [data, setData] = useState([]);
  const [ismodal, setModal] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [walletBalance,setWalletBalance] = useState('');
  const [CurrentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [userData, setuserData] = useState("");
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { fetchCustomersList } = bindActionCreators(actionCreators, dispatch);
  const toggle = () => setModal(!ismodal);
  

  let history = useHistory();  
  useEffect(() => {
    var data = JSON.parse(localStorage.getItem("UsedData"));
    setuserData(data);
  }, []);
  useEffect(() => {
    Data();
    WalletData();
  }, [CurrentPage]);
  const Data = async () => {
    HttpCallGet(
      GetCommissionList,
      GET,
      localStorage.getItem("Token"),
      CurrentPage
    )
      .then((res) => {
        console.log("response in tablee ", res);
        setData([]);
        setData(res.data.commissions);
        setPageData(res?.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log("error in table", err);
        toast.error("Error!! ", err, {
          position: "top-right",
        });
        setLoader(false);
      });
  };
  const WalletData = async () => {
    HttpCallGet(
      GetPartnerWalletBalance,
      GET,
      localStorage.getItem("Token"),
      CurrentPage
    )
      .then((res) => {
        console.log( res);
        setWalletBalance(res?.data.balance);
        // setData(res.data.commissions);
        // setPageData(res?.data);
        // setLoader(false);
      })
      .catch((err) => {
        console.log("error in table", err);
        toast.error("Error!! ", err, {
          position: "top-right",
        });
        setLoader(false);
      });
  };

  return (
    <>
      {/* Page content */}
      <Container className="mt--9" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Comission List</h3>
              </CardHeader>
              {loader == true ? (
                <div style={{ padding: "5px 10px" }}>
                  <ShimmerTable row={3} col={6} />
                </div>
              ) : (
                <>
                 <Row>
                        <Col xs="6" md="6">
                      <Card
                        style={{
                          padding: "10px",
                           width: "65%",
                          margin: "12px",
                        }}
                      >
                        Wallet Balance: { userData.currency +' '+ walletBalance==''?0:walletBalance}
                      </Card>
                      </Col>
                      <Col xs="6" md="6" style={{textAlign: "right",padding: "0 30px"}}>
                      <Button
                          className="my-2"
                          color="primary"
                          type="button"
                          onClick={() => {
                           // history.push("/admin/register");
                          toggle();
                          }}
                        >
                          Withdraw
                        </Button>
                      </Col>
                      </Row>
                  {data.length > 0 ? (
                    <>
                   
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Order Code</th>
                            <th scope="col">currency</th>
                            <th scope="col">Commission Amount</th>
                            <th scope="col">Date</th>
                            <th scope="col">Commission Status</th>
                            <th scope="col">Matured Date</th>

                            {/* <th scope="col">userID</th> */}
                            <th scope="col" />
                          </tr>
                        </thead>
                        {data &&
                          data.map((i, index) => {
                            console.log("iiiiiii",i)
                            return (
                              <tbody>
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>
                                    {i?.order_code ? i?.order_code : "NA"}
                                  </td>
                                  <td>{i?.currency ? i?.currency : "NA"}</td>
                                  <td>
                                    {i?.commission_amount
                                      ? i?.commission_amount
                                      : "NA"}
                                  </td>
                                  <td>{i?.date ? i?.date : "NA"}</td>
                                  <td>
                                    {i?.commissionStatus
                                      ? i?.commissionStatus
                                      : "NA"}
                                  </td>
                                  <td>
                                    {i?.matureDate
                                      ? i?.matureDate
                                      : "NA"}
                                  </td>
                                  

                                  {/* <td>{i?.userID ? i?.userID : "NA"}</td> */}
                                </tr>
                              </tbody>
                            );
                          })}
                      </Table>

                      <CardFooter className="py-4">
                        <nav>
                          <Pagination
                            className="pagination justify-content-end mb-0"
                            listClassName="justify-content-end mb-0"
                          >
                            <GPagination
                              currentPage={CurrentPage}
                              totalCount={pageData.totalRecords}
                              pageSize={pageData.limit}
                              onPageChange={(page) => setCurrentPage(page)}
                            />
                          </Pagination>
                        </nav>
                      </CardFooter>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <img
                          src={EmptyList}
                          alt="empty"
                          style={{
                            width: "250px",
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                     
                    </div>
                  )}
                </>
              )}
            </Card>
          </div>
        </Row>
        <Modal isOpen={ismodal} toggle={toggle}>
                <ModalHeader
                    toggle={toggle}>Withdraw Amount</ModalHeader>
                <ModalBody>
                   <WithdrawModel 
                   type = 'model'
                   toggle={toggle}/>
                </ModalBody>
                {/* <ModalFooter>
                    <Button color="primary" onClick={toggle}>Okay</Button>
                </ModalFooter> */}
            </Modal>
      </Container>
    </>
  );
}
