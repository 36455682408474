import { HttpCallPostToken } from "Apis";
import { ButtonLoader } from "components/Loaders/Loader";
import { ContainerLoader } from "components/Loaders/Loader";
import { ApplyOrderCoupon } from "network";
import { POST } from "network";
import { AddOrderProduct } from "network";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  FormText,
  Input,
  Row,
} from "reactstrap";
import OrderReview from "./OrderReview";

export default function ProductListSummary(props) {
  const [couponLoader, setCouponLoader] = useState(false);
  const [productLoader, setProductLoader] = useState(false);
  const [showFinal, setShowFinal] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [finalProductsDetail, setFinalProductsDetail] = useState([]);
  const [couponCode, setCouponCode] = useState("");

  var productArray = [];

  useEffect(() => {
    //  props.stndServiceListData
    console.log(props?.productList?.idList);
    props?.productList?.idList.map((s) =>
      productArray.push({
        id: s,
        instantDelivery: props.diliveryServices?.idList?.some((a) => a == s),
        additionalOption: props.additionalServices?.idList?.some((a) => a == s),
      })
    );
  }, [props?.stndServiceListData, props.productList]);
  const ApplyCoupon = () => {
    setCouponLoader(true);
    var data = { promocode: couponCode };
    console.log(productArray);
    HttpCallPostToken(
      ApplyOrderCoupon + props.orderId,
      POST,
      data,
      localStorage.getItem("Token")
    )
      .then((res) => {
        console.log("response 71 71", res.data);
        toast.success("Coupon Added Successfully", {
          position: "top-right",
        });
        setFinalProductsDetail(res.data);
        setCouponLoader(false);
      })
      .catch((err) => {
        console.warn("hiiiiiiiii", err.response.data.msg);
        toast.error(err.response.data.msg, {
          position: "top-right",
        });

        setCouponLoader(false);
      });
  };
  const AddProductOrder = () => {
    setProductLoader(true);
    var data = { products: productArray };
    console.log(productArray);
    HttpCallPostToken(
      AddOrderProduct + props.orderId,
      POST,
      data,
      localStorage.getItem("Token")
    )
      .then((res) => {
        console.log("response 71 71", res.data);
        toast.success("Product Added Successfully", {
          position: "top-right",
        });
        setFinalProductsDetail(res.data);
        setProductLoader(false);
        setShowFinal(true);
      })
      .catch((err) => {
        console.warn("hiiiiiiiii", err.response.data.msg);
        toast.error(err.response.data.msg, {
          position: "top-right",
        });

        setProductLoader(false);
      });
  };
  return (
    <>
      {" "}
      {showReview == false ? (
        <div style={{ float: "right", width: "33%" }}>
          <Card className="shadow">
            <CardHeader className="border-0">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h3 className="mb-2">Order Summary</h3>
              </div>
            </CardHeader>
            {productLoader == true ? (
             <ContainerLoader/>
            ) : (
              ""
            )}

            {props.activateMainPlan == "bndl" ? (
              <Container
                fluid
                style={{ position: "relative" }}
                className="mb-2"
              >
                {props?.productList.idList.map((mid, index) => (
                  <>
                    {props?.bndlServiceListData.map((item, index) => (
                      <>
                        {mid == item.id ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>{item.name}:</span>
                              <span
                                style={{ float: "right" }}
                              >{`${item.pricing?.currency} ${item.pricing?.price}`}</span>
                            </div>
                            <hr
                              style={{
                                marginTop: "0.8rem !important",
                                marginBottom: "0.8rem !important",
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </>
                ))}
                <div className="text-right">
                  <Button
                    className="my-4"
                    color="primary"
                    type="submit"
                    disabled={productLoader}
                    onClick={AddProductOrder}
                  >
                    Add Product
                  </Button>
                </div>
              </Container>
            ) : (
              <Container
                fluid
                style={{ position: "relative" }}
                className="mb-2"
              >
                {props?.productList.idList.map((mid, index) => (
                  <>
                    {props?.stndServiceListData.map((item, index) => (
                      <>
                        {mid == item.id ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>{item.name}:</span>
                              <span
                                style={{ float: "right" }}
                              >{`${item.pricing?.currency} ${item.pricing?.price}`}</span>
                            </div>
                            {props.diliveryServices?.idList?.some(
                              (a) => a == item.id
                            ) == true && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>Instant Delivery:</span>
                                <span
                                  style={{ float: "right" }}
                                >{`${item.pricing?.currency} ${item.pricing?.immediate_delivery}`}</span>
                              </div>
                            )}
                            {props.additionalServices?.idList?.some(
                              (a) => a == item.id
                            ) == true && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>Additional Option:</span>
                                <span
                                  style={{ float: "right" }}
                                >{`${item.pricing?.currency} ${item.pricing?.extra_option}`}</span>
                              </div>
                            )}
                            <hr
                              style={{
                                marginTop: "0.8rem !important",
                                marginBottom: "0.8rem !important",
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                        {item.addons.map((row, i) => (
                          <>
                            {" "}
                            {mid == row.id ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>{row?.name}:</span>
                                  <span>
                                    {" "}
                                    {`${row?.pricing?.currency} ${row?.pricing?.price}`}
                                  </span>
                                </div>
                                <hr
                                  style={{
                                    marginTop: "0.8rem !important",
                                    marginBottom: "0.8rem !important",
                                  }}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ))}
                      </>
                    ))}
                  </>
                ))}
                <div className="text-right">
                  <Button
                    className="my-4"
                    color="primary"
                    type="submit"
                    disabled={productLoader}
                    onClick={AddProductOrder}
                  >
                    Add Product
                  </Button>
                </div>
              </Container>
            )}
          </Card>
        </div>
      ) : (
        <div style={{ float: "left", width: "65%", }}>
          <OrderReview
            productsDetail={finalProductsDetail}
            setShowReview={setShowReview}
            setReviewVisible={props.setReviewVisible}
            orderId={props.orderId}
          />
        </div>
      )}
      {/* Final Order  */}
      {showFinal == true && (
        <Card className="shadow" style={{ float: "right", width: "33%" }}>
          <CardHeader className="border-0">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h3
                className="mb-2"
                onClick={() => {
                  console.log(finalProductsDetail);
                }}
              >
                Final Order Summary
              </h3>
            </div>
          </CardHeader>

          <Container fluid style={{ position: "relative" }} className="mb-2">
            {finalProductsDetail?.products?.map((item, index) => (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{item.name}:</span>
                  <span
                    style={{ float: "right" }}
                  >{`${finalProductsDetail?.currency} ${item.pricing?.price}`}</span>
                </div>
                {item.instantDelivery == true && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Instant Delivery:</span>
                    <span
                      style={{ float: "right" }}
                    >{`${finalProductsDetail?.currency} ${item.pricing?.instantDelivery}`}</span>
                  </div>
                )}
                {item.additionalOption == true && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>Additional Option:</span>
                    <span
                      style={{ float: "right" }}
                    >{`${finalProductsDetail?.currency} ${item.pricing?.additionalOption}`}</span>
                  </div>
                )}
                <hr
                  style={{
                    marginTop: "0.8rem !important",
                    marginBottom: "0.8rem !important",
                  }}
                />
              </>
            ))}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>GST Amount:</span>
              <span
                style={{ float: "right" }}
              >{`${finalProductsDetail?.currency} ${finalProductsDetail?.pricing?.gstAmount}`}</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>Gross Amount:</span>
              <span
                style={{ float: "right" }}
              >{`${finalProductsDetail?.currency} ${finalProductsDetail?.pricing?.grossAmount}`}</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>Total Amount:</span>
              <span
                style={{ float: "right" }}
              >{`${finalProductsDetail?.currency} ${finalProductsDetail.pricing?.netAmount}`}</span>
            </div>
            {showReview == false ? (
              <>
                <Row>
                  {" "}
                  <Col xs="6" md="6">
                    <Input
                      className="my-2"
                      type="text"
                      placeholder="Enter Code..."
                      value={couponCode}
                      onChange={(e) => {
                        setCouponCode(e.target.value);
                      }}
                    />
                  </Col>
                  <Col xs="6" md="6">
                    <Button
                      className="my-2"
                      color="primary"
                      type="submit"
                      disabled={couponLoader}
                      onClick={ApplyCoupon}
                    >
                     {couponLoader == true ? <ButtonLoader /> : "Apply Coupon"} 
                    </Button>
                  </Col>
                </Row>
                <Col xs="12" md="12">
                  <Button
                    className="my-2"
                    color="primary"
                    type="submit"
                    //disabled={loader}
                    onClick={() => {
                      setShowReview(true);
                      props.setReviewVisible(true);
                    }}
                  >
                    Review Order
                  </Button>
                </Col>
              </>
            ) : (
              ""
            )}
          </Container>
        </Card>
      )}
    </>
  );
}
