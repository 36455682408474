/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { Link, useHistory, withRouter } from "react-router-dom";
import { useState } from "react";
import toast from "react-hot-toast";
import { FaArrowCircleRight } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import { HttpCallPost } from "../Apis/index";
import { POST } from "network";
import { appcode } from "network";
import { veryfyOtp } from "network";
import { otpSend } from "network";
import styled from "styled-components";
import { ButtonLoader } from "components/Loaders/Loader";

const OtpBtn = styled.div`
  svg {
    cursor: pointer;
    &:hover {
      color: gray !important;
    }
  }
`;
const Login = () => {
  const [value, setValue] = useState(null);
  const [isd, setIsd] = useState(null);
  const [number, setNumber] = useState(null);
  let history = useHistory();
  const [showOtp, setShowOtp] = useState(false);
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState(false);
  const [isBtnDisabled, setBtnDisabled] = useState(false);

  const handleOnChange = (value, data, event, formattedValue) => {
    setIsd(data.dialCode);
    setNumber(value);
    // console.log('677777777',)
  };

  const sendOtp = async () => {
    if (number) {
      setShowOtp(true);
      var data = {
        mobile: number.slice(isd.length, number.length),
        isd: isd,
        appcode: appcode,
      };
      HttpCallPost(otpSend, POST, data)
        .then((res) => {
          console.log("response 71 71", res.data);
          toast.success(res.data.msg||"Otp send to your Registerd Mobile Number!", {
            position: "top-right",
          });
          setLoader(false);
        })
        .catch((err) => {
          console.warn("hiiiiiiiii", err.response.data.msg);
          toast.error(err.response.data.msg, {
            position: "top-right",
          });
          setShowOtp(false);
          setLoader(false);
        });
    } else {
      Swal.fire({
        position: "center",
        type: "error",
        title: "Please Enter Number",
      });
    }
  };

  const otpVerify = () => {
    if (otp.length > 3) {
      var data = {
        mobile: number.slice(isd.length, number.length),
        isd: isd,
        otp: otp,
        appcode: appcode,
      };
      HttpCallPost(veryfyOtp, POST, data)
        .then((res) => {
          localStorage.setItem("Token", res.data.token);
          localStorage.setItem("UsedData", JSON.stringify(res.data.userDetails));
          localStorage.setItem("PartnerDetails", JSON.stringify(res.data.partnerDetails));
          toast.success(res.data.msg||"Signed in Successfully", {
            position: "top-right",
          });
          setBtnDisabled(false);
          history.push("/admin");
          console.log("partnerDetails222222", res);
        })
        .catch((err) => {
          setBtnDisabled(false);
          console.log("errrrr", err.response.data);
          toast.error(err.response.data.msg, {
            position: "top-right",
          });
          // Swal.fire({
          //   position: "center",
          //   type: "error",
          //   title: err.response.data,
          // });
        });
    } else {
      setLoader(false);
      Swal.fire({
        position: "center",
        type: "error",
        title: "Please Enter OTP",
      });
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          {/* <CardHeader className="bg-transparent pb-5"> */}
          <div className="text-muted text-center mt-2 mb-3">
            <small>
              Sign in
              {/* with */}
            </small>
          </div>

          <CardBody className="px-lg-5 py-lg-4">
            {/* <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div> */}
            <Form role="form">
              <FormGroup className="mb-3"></FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative textarea-addon">
                  <InputGroupAddon>
                    <InputGroupText>
                      <PhoneInput
                        country={"in"}
                        value={value}
                        enableAreaCodeStretch
                        buttonStyle={{ borderColor: "#fff" }}
                        inputStyle={{
                          left: 50,
                          maxWidth: window.innerWidth >= 540?"220px":"165px",
                          borderColor: "#fff",
                        }}
                        onChange={handleOnChange}
                      />
                      <OtpBtn>
                        {loader == false ? (
                          <FaArrowCircleRight
                            color={"#000"}
                            size={28}
                            onClick={() => {
                              sendOtp();
                              setLoader(true);
                            }}
                          />
                        ) : (
                          <ButtonLoader />
                         
                        )}
                      </OtpBtn>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              {showOtp && (
                <>
                  <FormGroup>
                    <InputGroup className="input-group-alternative ">
                      <InputGroupAddon
                      // addonType="prepend"
                      >
                        <InputGroupText>
                          {/* <i className="ni ni-lock-circle-open" /> */}
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="OTP"
                        type="number"
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={() => {
                        setBtnDisabled(true);
                        otpVerify();
                      }}
                      disabled={isBtnDisabled}
                    >
                      {isBtnDisabled == true ? <ButtonLoader /> : "Sign in"} 
                    </Button>
                  </div>{" "}
                </>
              )}
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
