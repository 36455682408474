// import { HttpCallPostTokenForm } from "Apis";
import { HttpCallPostToken } from "Apis";
import { ButtonLoader } from "components/Loaders/Loader";
import { SendWithdrawAmount } from "network";
import { POST } from "network";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  Card,
  CardHeader,
  Container,
  Row,
  FormGroup,
  Input,
  Button,
  Col,
  FormText,
} from "reactstrap";
import * as yup from "yup";
import { bindActionCreators } from "redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { actionCreators } from "../../../redux";
import { EditcustomersList } from "network";

export default function EditModal(props) {
  const [loader, setLoader] = useState(false);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { customersList } = bindActionCreators(actionCreators, dispatch)

  useEffect(() => {
    console.log("props", props.customerData);
    // var data = JSON.parse(localStorage.getItem("PartnerDetails"));
    // setPartnerData(data);
    //?.userID
  }, []);
  const InitialData = {
    firstName: props.customerData?.firstname||"",
    lastName:  props.customerData?.lastname||"",
    email: props.customerData?.email||"",
  };
  const validationSchema = yup.object({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().required("Required"),
  });
  const edit = (values) => {
    let data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      customerId: props.customerData?.userID,
    };
    console.log("kkkkkkkkkkK", data);
    HttpCallPostToken(
      EditcustomersList,
      POST,
      data,
      localStorage.getItem("Token")
    )
      .then((res) => {
        console.log("response 71 71", res);
        toast.success("Updated Successfully", {
          position: "top-right",
        });
        props.toggle();
        // window.location.reload();
        setLoader(false);
        customersList(1);
      })
      .catch((err) => {
        console.warn("hiiiiiiiii", err.response?.data);
        toast.error(
          err.response.data.firstName && err.response.data.email
            ? err.response.data.firstName + "," + err.response.data.email
            : err.response.data.firstName
            ? err.response.data.firstName
            : err.response.data.email,
          {
            position: "top-right",
          }
        );
        setLoader(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={InitialData}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
          edit(values);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <Field name="name">
              {({ field, form }) => (
                <>
                  <FormGroup row>
                    <Col xs="12" md="12"  style={{paddingTop: "5px",}}>
                      <FormText color="muted">First Name</FormText>
                      <Input
                        type="text"
                        name="firstName"
                        placeholder="Enter First Name"
                        value={values.firstName}
                        onChange={(e) => {
                          setFieldValue("firstName", e.target.value);
                        }}
                        // readOnly={draftOrderId}
                      />
                      <ErrorMessage
                                  name="firstName"
                                  style={{
                                    color: "red",
                                    fontWeight: "600",
                                    fontSize: "12px",
                                  }}
                                  component={"div"}
                                />
                    </Col>
                    <Col xs="12" md="12"  style={{paddingTop: "5px",}}>
                      <FormText color="muted">Last Name</FormText>
                      <Input
                        type="text"
                        name="lastName"
                        placeholder="Enter Last Name"
                        value={values.lastName}
                        onChange={(e) => {
                          setFieldValue("lastName", e.target.value);
                        }}
                        // readOnly={draftOrderId}
                      />
                      <ErrorMessage
                                  name="lastName"
                                  style={{
                                    color: "red",
                                    fontWeight: "600",
                                    fontSize: "12px",
                                  }}
                                  component={"div"}
                                />
                    </Col>
                    <Col xs="12" md="12" style={{paddingTop: "5px",}}>
                      <FormText color="muted">Email address</FormText>
                      <Input
                        type="text"
                        name="email"
                        placeholder="Enter Email address"
                        value={values.email}
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                        }}
                        // readOnly={draftOrderId}
                      />
                      <ErrorMessage
                                  name="email"
                                  style={{
                                    color: "red",
                                    fontWeight: "600",
                                    fontSize: "12px",
                                  }}
                                  component={"div"}
                                />
                    </Col>
                  </FormGroup>
                </>
              )}
            </Field>
            <div className="text-right">
              <Button
                className="my-4"
                color="primary"
                type="submit"
                disabled={loader}
              >
                {loader == true ? <ButtonLoader /> : "Update"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
     
    </>
  );
}
