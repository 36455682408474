import React from 'react'
import { Container } from 'reactstrap';
import CircleLoader from "../../assets/placeholders/circle_loader.gif";

export const  ButtonLoader =()=>
{return (<>
    <img
                  src={CircleLoader}
                  alt="loader"
                  style={{ height: "28px" }}/>
  </>)}


export const  ContainerLoader =()=>{return (<>
   <Container
                fluid
                style={{
                  zIndex: "1",
                  justifyContent: "center",
                  position: "absolute",
                  height: "100%",
                  background: "#9e9e9e26",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={CircleLoader}
                  alt="loader"
                  style={{ height: "100px" }}
                />
              </Container>
</>)}


