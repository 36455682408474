import { BaseUrl } from "network";
import toast from "react-hot-toast";
import {api} from "../../services";

export  const customersList = (page) =>{
    return (dispatch) =>{
        dispatch({
            type:"FETCH_CUSTOMERS_LIST",
        })
        api('GET',BaseUrl+`partner/customer?page=${page}`).then((res)=>{
            console.log("console.log(res.data)",res.data)
            let data = JSON.parse(JSON.stringify(res)).data.customers
            
            data.map((customer,customerIndex)=>{
                customer.value = customer.id
                customer.label = `${customer.firstName} ${customer.lastName}`
            })
            setTimeout(()=>{
                dispatch({
                    type:"RECEIVE_CUSTOMERS_LIST",
                    payload:{data:data,totalRecords:res.data.totalRecords,limit:res.data.limit,page:res.data.page},
                })
            },1000)
        }).catch((err)=>{
            dispatch({
                type:"ERROR_CUSTOMERS_LIST",
            })
            window.location.reload();
            console.log("error in table", err);
        // toast.error("Error!! ", err, {
        //   position: "top-right",
        // });
        })
    }
}
