import React, { useEffect, useMemo, useState } from "react";
import { Container, Navbar, FormGroup, Form } from "reactstrap";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import styled from "styled-components";
import Switch from "react-switch";

const LogoutCont = styled.span`
  &:hover {
    color: #9e9e9e !important;
  }
`;

const AdminNavbar = (props) => {
  // console.log("propssssss",props.brandText=="Tables")
  let history = useHistory();
  let [userData, SetUserData] = useState("");

  useEffect(() => {
    var userdata = JSON.parse(localStorage?.getItem("UsedData"));
    console.log(userdata?.firstname);
    SetUserData(userdata);
  }, []);

  return (
    <div className="header bg-gradient-info pb-8 pt-9 pt-md-8">
      <Navbar className="navbar-top navbar-dark " expand="md" id="navbar-main">
        <Container fluid>
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingRight: 10,
                }}
              >
                 {/* <label style={{display: "flex"}}>
          <span style={{color: "#f9f6f1",fontSize: "13px"
}}>User Mode</span>
          <span  style={{padding:"0 6px",
          marginBottom: "-7px"}}>
        <Switch 
        checked={localStorage.getItem("mode")=='demo'?true:false}
        onChange={(e)=>{
          if(e){
            localStorage.setItem("mode","demo");
            history.push("/admin")
            
          }else{
            localStorage.setItem("mode","user");
            history.push("/auth/login")

          }
          window.location.reload();
          }}  /></span>
           <span  style={{color: "#f9f6f1",fontSize: "13px"}}>Demo Mode</span>

      </label> */}
                <span
                  style={{
                    color: "#fff",
                    fontWeight: "500",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                >
                  <i
                    class="fa fa-user"
                    aria-hidden="true"
                    style={{ padding: "0 2px" }}
                  />
                  {userData?.firstname||"Demo USER"} ({userData?.contact||"Demo contact"})
                </span>
                <LogoutCont
                  style={{
                    color: "#fff",
                    fontWeight: "bolder",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    localStorage.setItem("Token", "");
                    history.push("/auth/login");
                    toast.error("Logged Out", {
                      position: "top-right",
                    });
                  }}
                >
                  Logout
                </LogoutCont>
               
              </div>
            </FormGroup>
          </Form>
        </Container>
      </Navbar>
    </div>
  );
};

export default AdminNavbar;
