// import { BaseUrl } from './Networks';

import { BaseUrl } from "../network/index";
import Swal from "sweetalert2";
// import axios from 'axios';
const axios = require('axios');

// For Post Api Calls And Put
export const HttpCallPost = async (method, type,  body) => {
  return new Promise(async function (resolve, reject) {
    const url = BaseUrl + method;
    axios({
      method: type,
      url: url,
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
    //     "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        // 'x-api-key': `${token}`
      },
      data: body
    }).then(response => {
      if (response.status === 200) {
        return resolve(response);
      }
      return resolve(response);
    })
      .catch(err => {
        return reject(err);
      });
  });
};

export const HttpCallPostToken = async (method, type,  body,token) => {
  return new Promise(async function (resolve, reject) {
    const url = BaseUrl + method;
    const headerPrem = 
    // localStorage.getItem("mode")=='demo'?{
    //   'content-type': 'application/json',
    //   Accept: 'application/json',
        
    // }:
    {
      'content-type': 'application/json',
      Accept: 'application/json',
     'x-api-key': `${token}`,
    }
    axios({
      method: type,
      url: url,
      headers:headerPrem,
      data: body
    }).then(response => {
      if (response.status === 200) {
        return resolve(response);
      }
      return resolve(response);
    })
      .catch(err => {
        return reject(err);
      });
  });
};

export const HttpCallPostTokenForm = async (method, type,  body,token) => {
  return new Promise(async function (resolve, reject) {
    const url = BaseUrl + method;
    const headerPrem =
    //  localStorage.getItem("mode")=='demo'?{
    //   'content-type': 'application/json',
    //   Accept: 'application/json',
    //   "Access-Control-Allow-Origin": "*",
    //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        
    // }:
    {
     
        'content-type': 'multipart/form-data',
        Accept: 'application/json',
        'x-api-key': `${token}`,
       
    }
    axios({
      method: type,
      url: url,
      headers: headerPrem,
      data: body
    }).then(response => {
      if (response.status === 200) {
        return resolve(response);
      }
      return resolve(response);
    })
      .catch(err => {
        return reject(err);
      });
  });
};

export const HttpCallPut = async (method, type, token, body) => {
  return new Promise(async function (resolve, reject) {
    const url = BaseUrl + method;
    const headerPrem = 
    // localStorage.getItem("mode")=='demo'?{
    //   'content-type': 'application/json',
    //   Accept: 'application/json',
    //   "Access-Control-Allow-Origin": "*",
    //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        
    // }:
    {
     
        'content-type': 'multipart/form-data',
        Accept: 'application/json',
        'x-api-key': `${token}`,
       
    }
    axios({
      method: type,
      url: url,
      headers: headerPrem,
      data: body
    }).then(response => {
      if (response.status === 200) {
        return resolve(response);
      }
      return resolve(response);
    })
      .catch(err => {
        return reject(err);
      });
  });
};

//For Get Api Calls
export const HttpCallGet = async (method, type,
   token,page,query 
   ) => {
  return new Promise(async function (resolve, reject) {
    const url = BaseUrl + method +(page?`?page=${(page||1)}`:"");
    const headerPrem =
    //  localStorage.getItem("mode")=='demo'?{
    //   'content-type': 'application/json',
    //   Accept: 'application/json',
    //   "Access-Control-Allow-Origin": "*",
    //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        
    // }:
    {
     
        'content-type': 'multipart/form-data',
        Accept: 'application/json',
        'x-api-key': `${token}`,
       
    }
    axios({
      method: type,
      url: url,
      headers:headerPrem,
    }).then(response => {
      if (response.status === 304||response.status === 400) {
        return resolve(response);
      }
      return resolve(response);
    })
      .catch(err => {
        //console.log(err)
        return reject(err);
       
      });
  });
};
//For Get Api Calls
export const HttpCallGetWithoutKey = async (method, type,
  token,page,id
  ) => {
 return new Promise(async function (resolve, reject) {
   const url = BaseUrl + method +(page?`?page=${(page||1)}`:"");

   axios({
     method: type,
     url: url,
     headers: {
       'content-type': 'application/json',
       Accept: 'application/json',
       "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      //  'x-api-key': `${token}`
     },
   }).then(response => {
     if (response.status === 304) {
       return resolve(response);
     }
     return resolve(response);
   })
     .catch(err => {
        return reject(err);
     });
 });
};

//For Api Error Handling Globaly
export const handleError = (errResponse) => {
  if (errResponse.response.status === 403) {
    localStorage.clear()
    return Swal.fire({
      position: 'center',
      type: 'error',
      title: 'Invalid User.Please Login Again...',
    }).then((ok) => {
      window.location.assign('/login')
    })
  }
  else if (errResponse.response.status === 404) {
    Swal.fire({
      position: 'center',
      type: 'error',
      title: errResponse.response.data,
    })
  }
  else if (errResponse.response.status === 500) {
    Swal.fire({
      position: 'center',
      type: 'error',
      title: errResponse.response.data,
    })
  }
  else if (errResponse.response.status === 400) {
    Swal.fire({
      position: 'center',
      type: 'error',
      title: errResponse.response.data,
    })
  }
}