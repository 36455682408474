const CustomersReducer = (state = {
    loading:false,
    customers:[],totalRecords:0,limit:10,page:1,
}, action) => {
    console.log(action)
    switch (action.type) {
        case "FETCH_CUSTOMERS_LIST":
            return { ...state ,loading:true,customers:[],totalRecords:0,limit:10,page:1}
        case "RECEIVE_CUSTOMERS_LIST":
            return {...state, customers:[...action.payload.data],loading:false,totalRecords:action.payload.totalRecords,limit:action.payload.limit,page:action.payload.page}
        case "ERROR_CUSTOMERS_LIST":
            return { ...state ,customers:[],loading:false,totalRecords:0,limit:10,page:1}
        default:
            return { ...state }
    }
}

export default CustomersReducer;