import { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  Container,
  Row,
  Col,
  input,
  CustomInput,
} from "reactstrap";
import Switch from "react-switch";
import styled from "styled-components";

const ToggelButton = styled.label`
.switch{
  .react-switch-bg{
    height: 20px;
  }
}

`
const AdminNavbar = () => {
  let history = useHistory();

  return (
    <>
      <Navbar className="navbar-top nnavbar-dark" expand="md">
        <Container className="px-4">
          <NavbarBrand to="/" tag={Link}>
            <img
              alt="..."
              style={{ maxHeight: window.innerWidth >= 540?"4rem":"2.5rem" }}
              src={require("../../assets/logo/logo-main.png")?.default}
            />
          </NavbarBrand>
          
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
         
          {/* <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt="..."
                      src={require("../../assets/logo/logo-main.png")?.default}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
          </UncontrolledCollapse> */}
         
          <ToggelButton style={{display: "flex"}}>
          <span style={{color: "#f9f6f1", fontSize: "13px"}}>Live</span>
          <span  style={{padding:"0 4px",}}>
        <Switch 
        className="switch"
        checked={localStorage.getItem("mode")=='demo'?true:false}
            onColor="#1b5231"
            offColor="#3AB54A"
            // onHandleColor="#2693e6"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={18}
            width={45}
            id="material-switch"
        onChange={(e)=>{
          if(e){
            localStorage.setItem("mode","demo");
          // history.push("/admin")
            
          }else{
            localStorage.setItem("mode","user");
          //  history.push("/auth/login")

          }
          window.location.reload();
          }}  /></span>
           <span  style={{color: "#f9f6f1",fontSize: "13px"}}>Demo </span>

      </ToggelButton>

        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
