
import { HttpCallGet } from "Apis";
import { useEffect, useState } from "react";
import { customerList } from "network";
import { GET } from "network";
import EmptyList from "../../assets/placeholders/emptylist.jpg";
import { ShimmerTable } from "react-shimmer-effects";
import toast from "react-hot-toast";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Pagination,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";

import { useHistory } from "react-router-dom";
import GPagination from "components/Pagination/GPagination.js";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import EditModal from "./Addcustomer/EditModal";
// core components
//var customerId = null
const CustomerList = () => {
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [ismodal, setModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState('');
  const [editCustomerData, setEditCustomerData] = useState('');
  const [CurrentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [pageData, setPageData] = useState([]);
  const state = useSelector((state) => state)
  const dispatch = useDispatch();
  // const { customersList } = bindActionCreators(actionCreators, dispatch)

  const toggle = (i) => {
    console.log("iiiiiii",i,ismodal)
    setEditCustomerData(i);   
    setModal(!ismodal)    

  }

  //const { fetchCustomersList } = bindActionCreators(actionCreators,dispatch)

  //const products = useSelector((state) => state.customers);
  let history = useHistory();
  // useEffect(() => {
  //   customersList(CurrentPage);
  // }, [])
  useEffect(() => Data(), [CurrentPage]);
  // useEffect(() => {
  //   console.log(state?.customers.customers, "localStorage.getItem", localStorage.getItem("Token"))

  //   if (state?.customers?.customers !== "") {
  //     console.log(state?.customers?.loading)
  //     setData(state?.customers.customers);
  //     setCurrentPage(state?.customers?.page);
  //     setTotalRecords(state?.customers?.totalRecords);
  //     setLoader(state?.customers?.loading);
  //     setLimit(state?.customers?.limit);
  //   }

  // }, [state])
  const Data = async () => {
    HttpCallGet(customerList, GET, localStorage.getItem("Token"))
      .then((res) => {
        console.log("response in tablee ", res);
        console.log(res?.data?.userDetails, res);

        setData([]);
        setPageData(res?.data);
        setData(res?.data?.customers||res?.data?.userDetails);
        setLoader(false);
      })
      .catch((err) => {
        console.log("error in table", err);
        toast.error("Error!! ", err, {
          position: "top-right",
      });
        setLoader(false);
      });
  };


  return (
    <>
      {/* Page content */}
      <Container className="mt--9" fluid>
        {/* Table */}
        <Row>

          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Customer List</h3>

              </CardHeader>
              {loader == true ? (
                <div style={{ padding: "5px 10px" }}>
                  <ShimmerTable row={3} col={6} />
                </div>
              ) : (
                <>
                  {data?.length > 0 ? (
                    <>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">firstname</th>
                            <th scope="col">lastname</th>
                            <th scope="col">isdCode</th>

                            <th scope="col">contact</th>
                            <th scope="col">email</th>
                            <th scope="col">linkStatus</th>
                            <th scope="col">Action</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        {data &&
                          data.map((i, index) => {                          
                            return (
                              <tbody>
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>{i?.firstname ? i?.firstname : "NA"}</td>
                                  <td>{i?.lastname ? i?.lastname : "NA"}</td>
                                  <td>{i?.isdCode ? i?.isdCode : "NA"}</td>

                                  <td>{i?.contact ? i?.contact : "NA"}</td>
                                  <td>{i?.email ? i?.email : "NA"}</td>
                                  <td>
                                    {i?.linkStatus ? i?.linkStatus : "NA"}
                                  </td>
                                  <Button
                                    className="my-2"
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                      let j = i
                                      toggle(j)
                                    }}
                                  >
                                    EDIT
                                  </Button>
                                  {/* <td>{i?.userID ? i?.userID : "NA"}</td> */}
                                </tr>
                              </tbody>
                            );
                          })}
                      </Table>

                      <CardFooter className="py-4">
                        <nav>
                          <Pagination
                            className="pagination justify-content-end mb-0"
                            listClassName="justify-content-end mb-0"
                          >
                            <GPagination
                              currentPage={CurrentPage}
                              totalCount={totalRecords}
                              pageSize={limit}
                              onPageChange={(page) => setCurrentPage(page)}
                            />
                          </Pagination>
                        </nav>
                      </CardFooter>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <img
                          src={EmptyList}
                          alt="empty"
                          style={{
                            width: "250px",
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          style={{ width: "180px" }}
                          onClick={() => {
                            history.push("/admin/link-customer");
                          }}
                        >
                          Add Customer
                        </Button>

                      </div>
                    </div>
                  )}
                </>
              )}

            </Card>
          </div>
        </Row>

        <Modal isOpen={ismodal} toggle={toggle}>
          <ModalHeader            
            toggle={toggle}>EDIT</ModalHeader>
          <ModalBody>
            <EditModal
              type='model'
              customerData={editCustomerData}
              toggle={toggle} />
          </ModalBody>
          {/* <ModalFooter>
                    <Button color="primary" onClick={toggle}>Okay</Button>
                </ModalFooter> */}
        </Modal>
      </Container>
    </>
  );
};

export default CustomerList;
