
import CustomerList from "layouts/CustomerManagement/CustomerList";
import PartnerLeadList from "layouts/CustomerManagement/PartnerLeadList";
import CreateDraftOrder from "layouts/OrderManagement/CreateDraftOrder";
import Index from "layouts/Index";
import AddCustomer from "layouts/CustomerManagement/Addcustomer/AddCustomer";
import OrderList from "layouts/OrderManagement/OrderList";
import ComissionList from "layouts/CommissionManagement/ComissionList";
import WithdrawalRequestList from "layouts/CommissionManagement/WithdrawalRequestList";


var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    type:"default"
  },
  {
    path: "/customer-list",
    name: "My Customer",
    icon: "ni ni-bullet-list-67 text-red",
    component: CustomerList,
    layout: "/admin",
    type:"customer"

  },
  {
    path: "/PartnerList",
    name: "Incoming Leads",
    icon: "ni ni-bullet-list-67 text-red",
    component: PartnerLeadList,
    layout: "/admin",
    type:"customer"

  },
  {
    path: "/create-draft-order",
    name: "Create Draft Order",
    icon: "ni ni-fat-add text-red",
    component: CreateDraftOrder,
    layout: "/admin",
    type:"order"

  }, 
  {
    path: "/order-list",
    name: "Partner Order List",
    icon: "ni ni-bullet-list-67 text-red",
    component: OrderList,
    layout: "/admin",
    type:"order"

  },
  {
    path: "/link-customer",
    name: "Link Customer",
    icon: "ni ni-circle-08 text-pink",
    component: AddCustomer,
    layout: "/admin",
    type:"customer"

  },
  {
    path: "/commission-list",
    name: "Commission list",
    icon: "ni ni-bullet-list-67 text-red",
    component: ComissionList,
    layout: "/admin",
    type:"commission",
  }
  ,
  {
    path: "/withdrawal-request-list",
    name: "Withdrawal Request List",
    icon: "ni ni-bullet-list-67 text-red",
    component:  WithdrawalRequestList,
    layout: "/admin",
    type:"commission",
  }

];
export default routes;
