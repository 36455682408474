
import { HttpCallGet } from "Apis";
import { useEffect, useState } from "react";
import { partnerList } from "network";
import { GET } from "network";
import EmptyList from "../../assets/placeholders/emptylist.jpg";
import { ShimmerTable } from "react-shimmer-effects";
import toast from "react-hot-toast";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import GPagination from "components/Pagination/GPagination";
import AddCustomerModel from "./Addcustomer/AddCustomerModel";
// core components

const PartnerLeadList = () => {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [loader, setLoader] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [CurrentPage, setCurrentPage] = useState(1);
  const toggle = () => setModal(!modal);
  useEffect(() => Data(), [CurrentPage]);
  const Data = async () => {
    HttpCallGet(partnerList, GET, localStorage.getItem("Token"))
      .then((res) => {
        console.log("response in tablee ", res);
        setData([]);
        setPageData(res?.data);
        setData(res?.data?.leads);
        setLoader(false);
      })
      .catch((err) => {
        console.log("error in table", err);
        toast.error("Error!! ", err, {
          position: "top-right",
      });
        setLoader(false);
      });
  };

  return (
    <>
      {/* Page content */}
      <Container className="mt--9" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Partner Lead List</h3>
              </CardHeader>
              {loader == true ? (
                <div style={{ padding: "5px 10px" }}>
                  <ShimmerTable row={3} col={6} />
                </div>
              ) : (
                <>
                  {data?.length > 0 ? (
                    <>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">firstname</th>
                            <th scope="col">lastname</th>
                            <th scope="col">isdCode</th>

                            <th scope="col">contact</th>
                            <th scope="col">email</th>
                            {/* <th scope="col">userID</th> */}
                            <th scope="col">manager</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        {data &&
                          data.map((i, index) => {
                            return (
                              <tbody>
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>{i?.firstname ? i?.firstname : "NA"}</td>
                                  <td>{i?.lastname ? i?.lastname : "NA"}</td>
                                  <td>{i?.isdCode ? i?.isdCode : "NA"}</td>
                                  <td>{i?.contact ? i?.contact : "NA"}</td>
                                  <td>{i?.email ? i?.email : "NA"}</td>
                                  {/* <td>{i?.userID ? i?.userID : "NA"}</td> */}

                                  <td>
                                    {i?.manager.firstname ? i?.manager?.firstname + ' '+ i?.manager?.lastname : "NA"}
                                  </td>
                                  <td>
                                  <Button
                            // className="my-4"
                            color="primary"
                            type="button"
                            onClick={()=>{toggle();
                            setSelectedCustomer(i);
                            }}
                          >
                            Link
                          </Button>
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                      </Table>

                      <CardFooter className="py-4">
                        <nav aria-label="...">
                          <Pagination
                            className="pagination justify-content-end mb-0"
                            listClassName="justify-content-end mb-0"
                          >
                             <CardFooter className="py-4">
                        <nav>
                          <Pagination
                            className="pagination justify-content-end mb-0"
                            listClassName="justify-content-end mb-0"
                          >
                            <GPagination
                              currentPage={CurrentPage}
                              totalCount={pageData.totalRecords}
                              pageSize={pageData.limit}
                              onPageChange={(page) => setCurrentPage(page)}
                            />
                          </Pagination>
                        </nav>
                      </CardFooter>
                          </Pagination>
                        </nav>
                      </CardFooter>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <img
                          src={EmptyList}
                          alt="empty"
                          style={{
                            width: "250px",
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    
                    </div>
                  )}
                </>
              )}
            </Card>
          </div>
        </Row>
        <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader
                    toggle={toggle}>Link Customer</ModalHeader>
                <ModalBody>
                   <AddCustomerModel selectedCustomer={selectedCustomer} 
                   type = 'model'
                   toggle={toggle}/>
                </ModalBody>
                {/* <ModalFooter>
                    <Button color="primary" onClick={toggle}>Okay</Button>
                </ModalFooter> */}
            </Modal>
      </Container>
     
    </>
  );
};

export default PartnerLeadList;
