
import { HttpCallGet } from "Apis";
import { useEffect, useState } from "react";
import { customerList } from "network";
import Select from "react-select";
import { GET } from "network";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Card,
  CardHeader,
  Container,
  Row,
  FormGroup,
  Input,
  Button,
  Col,
  FormText, 
   CardFooter,
  Table,
  Pagination,
} from "reactstrap";
import EmptyList from "../../assets/placeholders/emptylist.jpg";
import { ShimmerTable } from "react-shimmer-effects";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import GPagination from "components/Pagination/GPagination.js";
import { bindActionCreators } from "redux";
import actionCreators from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { GetOrderList } from "network";
import styled from "styled-components";
import { ButtonLoader } from "components/Loaders/Loader";

// core components
const StatusOptions = [
  { label: "Active", value: "Active" },
  { label: "Pending client Approval", value: "Pending client Approval" },
  { label: "Cancelled", value: "Cancelled" },
];
const FilterDiv = styled.div`
padding:0 30px;
.form-group {
  margin-bottom: 0 !important;
}
`
const OrderList = () => {
  const [data, setData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [filterValue, SetFilterValues] = useState('');
  const [customerListData, setCustomerListData] = useState("");
  const state = useSelector((state)=>state)
  const dispatch = useDispatch();
  const { fetchCustomersList } = bindActionCreators(actionCreators,dispatch)

  let history = useHistory();
  const InitialData = {
    customerID: "",
    status: "",
    orderCode: ""
  };
//   useEffect(()=>{
//     fetchCustomersList()
// },[])

// useEffect(() => {
//    console.log(state.fetchCustomersList)
// }, [state])
  useEffect(() => {Data();
  }, [CurrentPage,filterValue]);
  const Data = async () => {
   // console.log(values)
    var filter = `&orderCode=${filterValue?.orderCode||""}&status=${filterValue?.status||""}&orderCreateStartDate&orderCreateEndDate&customerID=${filterValue?.customerID||""}`
    HttpCallGet(GetOrderList, GET, localStorage.getItem("Token"),CurrentPage+filter)
      .then((res) => {
       // console.log("response in tablee ", res.data.orders.length);
        setData([]);
        setData(res.data.orders);
       // setPageData(res?.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log( err);
        toast.error("Error!! ", err, {
          position: "top-right",
        });
        setLoader(false);
      });
  };
  useEffect(() => customerData(), []);
  const customerData = async () => {
    HttpCallGet(customerList, GET, localStorage.getItem("Token"))
      .then((res) => {
        setCustomerListData(
          res?.data?.customers.map((data) => ({
            label:
              data?.firstname !== null
                ? `${data?.firstname} ${data?.lastname}(${data?.contact})`
                : `${data?.contact}`,
            value: data.userID,
          }))
        );
      })
      .catch((err) => {
        console.log("error in table", err);
        toast.error("Error!! ", err.data.msg, {
          position: "top-right",
        });
      });
  };
  return (
    <>
      {/* Page content */}
      <Container className="mt--9" fluid>
       
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Order List</h3>
              </CardHeader>
              {loader == true ? (
                <div style={{ padding: "5px 10px" }}>
                  <ShimmerTable row={3} col={6} />
                </div>
              ) : (
                <>
                  
                    <FilterDiv>
                    <Formik
                   initialValues={InitialData}
                  //validationSchema={validationSchema}
                  onSubmit={(values) => {
                    SetFilterValues(values)
                  }}
                >
                  {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                      <Field name="name">
                        {({ field, form }) => (
                          <>
                            <FormGroup row>
                              <Col xs="12" md="4">
                                <FormText color="muted">Customer</FormText>
                                <Select
                                  value={customerListData.label}
                                  name="customerID"
                                  onChange={(e) => {
                                    setFieldValue("customerID", e.value);
                                  }}
                                 options={customerListData}
                                 // isDisabled={draftOrderId}
                                  />
                              
                              </Col>
                              <Col xs="12" md="4">
                                <FormText color="muted">Status</FormText>
                                <Select
                                  name="status"
                                  value={StatusOptions.label}
                                  onChange={(e) => {
                                    setFieldValue("status", e.value);
                                  }}
                                  options={StatusOptions}
                                 // isDisabled={draftOrderId}

                                />
                              </Col>
                              <Col xs="12" md="4">
                                <FormText color="muted">Order Code</FormText>
                                <Input
                                  type="text"
                                  name="orderCode"
                                  placeholder="Enter Order Code"
                                  value={values.orderCode}
                                  onChange={(e) => {
                                  setFieldValue("orderCode", e.target.value);
                                  }}
                                 // readOnly={draftOrderId}
                                />
                              </Col>
                             
                            </FormGroup>
                           
                              
                          </>
                        )}
                      </Field>
                        <div className="text-right">
                          <Button
                            className="my-4"
                            color="primary"
                            type="submit"
                            disabled={loader}
                          >
                           {loader == true ? <ButtonLoader /> : "Filter"}  
                          </Button>
                          <Button
                            className="my-4"
                            color="primary"
                           // type="submit"
                           onClick={()=>{
                            setFieldValue("customerID", '');
                            setFieldValue("orderCode", '');
                            setFieldValue("status",'');
                           }}
                            disabled={loader}
                          >
                             {loader == true ? <ButtonLoader /> : "Clear"}   
                          </Button>
                        </div>
                    </Form>
                  )}
                </Formik>
                    </FilterDiv>
                    {data?.length > 0 ? (
                    <>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Order Number</th>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Customer Mobile</th>

                            <th scope="col">Creation Date</th>
                            <th scope="col">Gross Amt</th>
                            <th scope="col">Net Amt</th>
                            {/* <th scope="col">Created By</th> */}
                            {/* <th scope="col">Assigned To</th> */}
                            <th scope="col">Status</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        {data &&
                          data.map((i, index) => {
                            return (
                              <tbody>
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>{i?.orderCode ? i?.orderCode : "NA"}</td>
                                  <td>{i?.customer?.firstName ? `${i?.customer?.firstName} ${i?.customer?.lastName}`  : "NA"}</td>
                                  <td>{i?.customer?.phoneNumber ? i.customer.phoneNumber : "NA"}</td>
                                  <td>{i?.orderedAt ? i?.orderedAt : "NA"}</td>
                                  <td>{i?.orderAmount ? i?.orderAmount : "NA"}</td>
                                  <td>{i?.orderTotalAmount ? i?.orderTotalAmount : "NA"}</td>
                                  <td>{i?.orderStatus ? i?.orderStatus : "NA"}</td>
                                 
                                  {/* <td>{i?.userID ? i?.userID : "NA"}</td> */}
                                </tr>
                              </tbody>
                            );
                          })}
                      </Table>

                      <CardFooter className="py-4">
                        <nav>
                          {/* <Pagination
                            className="pagination justify-content-end mb-0"
                            listClassName="justify-content-end mb-0"
                          >
                            <GPagination
                              currentPage={CurrentPage}
                              totalCount={pageData.totalRecords}
                              pageSize={pageData.limit}
                              onPageChange={(page) => setCurrentPage(page)}
                            />
                          </Pagination> */}
                        </nav>
                      </CardFooter>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <img
                          src={EmptyList}
                          alt="empty"
                          style={{
                            width: "250px",
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          style={{ width: "180px" }}
                          onClick={() => {
                            history.push("/admin/create-draft-order");
                          }}
                        >
                          Create Draft Order
                        </Button>
                        
                      </div>
                    </div>
                  )}
                </>
              )}

            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OrderList;
