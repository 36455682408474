
/*eslint-disable*/
import { useState,useEffect } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import mainLogo from '../../assets/logo/logo-main.png'

// reactstrap components
import {
  Collapse,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";


var ps;
const LogoutCont = styled.span`
  &:hover {
    color: #9e9e9e !important;
  }
`;

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  let history = useHistory();
  let [userData, SetUserData] = useState("");

  useEffect(() => {
    var userdata = JSON.parse(localStorage?.getItem("UsedData"));
    console.log(userdata?.firstname);
    SetUserData(userdata);
  }, [])
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes,linkType) => {
    return routes.map((prop, key) => {
      if(linkType==prop.type)
      {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );}
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              //className="navbar-brand-img"
              src={mainLogo}
              style={{maxHeight: "4rem"}}
            />
          </NavbarBrand>
        ) : null}
      
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={mainLogo} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={mainLogo} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
            <Row style={{paddingTop: "20px",
    paddingLeft: "10px"}}>
            <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingRight: 10,
                }}
              >
                 
                <span
                  style={{
                    color: "#000",
                    fontWeight: "500",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                >
                  <i
                    class="fa fa-user"
                    aria-hidden="true"
                    style={{ padding: "0 2px" }}
                  />
                  {userData?.firstname||"Demo USER"} ({userData?.contact||"Demo contact"})
                </span>
                <LogoutCont
                  style={{
                    color: "#000",
                    fontWeight: "bolder",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    localStorage.setItem("Token", "");
                    history.push("/auth/login");
                    toast.error("Logged Out", {
                      position: "top-right",
                    });
                  }}
                >
                  Logout
                </LogoutCont>
               
              </div>
            </Row>
          </div>
          {/* Form */}
          <Form className="mt-4 mb-3 d-md-none">
            {/* <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup> */}
          </Form>
          {/* Navigation */}
          <Nav navbar>{createLinks(routes,'default')}</Nav>
          {/* Divider */}
          <hr className="my-3" />
          {/* Heading */}
          <h6 className="navbar-heading text-muted">Customer Management</h6>
          <Nav navbar>{createLinks(routes,'customer')}</Nav>
          <hr className="my-3" />
          {/* Heading */}
          <h6 className="navbar-heading text-muted">Order Management</h6>
          <Nav navbar>{createLinks(routes,'order')}</Nav>
          <hr className="my-3" />
          <h6 className="navbar-heading text-muted">Commission Management</h6>
          <Nav navbar>{createLinks(routes,'commission')}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
