import axios from "axios";

let headers = 
//  localStorage.getItem("mode")=='demo'?{
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
//}:
{ 'x-api-key': `${localStorage.getItem("Token")}`,
"Access-Control-Allow-Origin": "*",
"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"};

let mode = localStorage.getItem("mode")
const mmh = axios.create({
    baseURL:mode=="demo"?"https://mmh-mock-api-y5bu.onrender.com/":process.env.REACT_APP_API_URL,
    headers,
});


export { mmh };