import { HttpCallGet } from "Apis";
import { useEffect, useState } from "react";
import { partnerList } from "network";
import { GET } from "network";
import EmptyList from "../../assets/placeholders/emptylist.jpg";
import { ShimmerTable } from "react-shimmer-effects";
import toast from "react-hot-toast";
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
import GPagination from "components/Pagination/GPagination";
import { WithdrawalRequest } from "network";
// core components
export default function WithdrawalRequestList() {
  
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [loader, setLoader] = useState(true);
    const [pageData, setPageData] = useState([]);
    const [CurrentPage, setCurrentPage] = useState(1);
    const toggle = () => setModal(!modal);
    useEffect(() => Data(), [CurrentPage]);
    const Data = async () => {
      HttpCallGet(WithdrawalRequest, GET, localStorage.getItem("Token"))
        .then((res) => {
          console.log("response in tablee ", res);
          setData([]);
          setPageData(res?.data);
          setData(res?.data?.withdrawalRequest);
          setLoader(false);
        })
        .catch((err) => {
          console.log("error in table", err);
          toast.error("Error!! ", err, {
            position: "top-right",
        });
          setLoader(false);
        });
    };
  
    return (
      <>
        {/* Page content */}
        <Container className="mt--9" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Withdrawal Request List</h3>
                </CardHeader>
                {loader == true ? (
                  <div style={{ padding: "5px 10px" }}>
                    <ShimmerTable row={3} col={6} />
                  </div>
                ) : (
                  <>
                    {data?.length > 0 ? (
                      <>
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Request date</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Date</th>
  
                              <th scope="col">Status</th>
                              <th scope="col">Comment by admin</th>
                              <th scope="col" />
                            </tr>
                          </thead>
                          {data &&
                            data.map((i, index) => {
                              return (
                                <tbody>
                                  <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{i?.requestdate ? i?.requestdate : "NA"}</td>
                                    <td>{i?.amount ? i?.amount : "NA"}</td>
                                    <td>{i?.date ? i?.date : "NA"}</td>
                                    <td>{i?.status ? i?.status : "NA"}</td>
                                    <td>{i?.comment ? i?.comment : "NA"}</td>
                                    {/* <td>{i?.userID ? i?.userID : "NA"}</td> */}
  
                                  
                                   
                                  </tr>
                                </tbody>
                              );
                            })}
                        </Table>
  
                        <CardFooter className="py-4">
                          <nav aria-label="...">
                            <Pagination
                              className="pagination justify-content-end mb-0"
                              listClassName="justify-content-end mb-0"
                            >
                               <CardFooter className="py-4">
                          <nav>
                            <Pagination
                              className="pagination justify-content-end mb-0"
                              listClassName="justify-content-end mb-0"
                            >
                              <GPagination
                                currentPage={CurrentPage}
                                totalCount={pageData.totalRecords}
                                pageSize={pageData.limit}
                                onPageChange={(page) => setCurrentPage(page)}
                              />
                            </Pagination>
                          </nav>
                        </CardFooter>
                            </Pagination>
                          </nav>
                        </CardFooter>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{ justifyContent: "center", display: "flex" }}
                        >
                          <img
                            src={EmptyList}
                            alt="empty"
                            style={{
                              width: "250px",
                              height: "200px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      
                      </div>
                    )}
                  </>
                )}
              </Card>
            </div>
          </Row>
       
        </Container>
       
      </>
    );
}
