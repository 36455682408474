//import * as customersList  from "./getCustomersList"
import { customersList } from "./getCustomersList"
import { editcustomersList } from "./editcustomersList"
//import  {partnerLeadList}  from "./getPartnerLeadList"


const actions = {
    customersList: customersList,
    editcustomersList: editcustomersList,
    //  partnerLeadList:partnerLeadList,

}
// const actions = {
//     ...customersList,
// }
export default actions