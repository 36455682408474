import { HttpCallPostTokenForm } from "Apis";
import { HttpCallPostToken } from "Apis";
import { ButtonLoader } from "components/Loaders/Loader";
import { SendWithdrawAmount } from "network";
import { POST } from "network";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Button, Form, FormGroup, FormText, Input } from "reactstrap";

export default function WithdrawModel() {
  const [amount, setAmount] = useState("");
  const [partnerData, setPartnerData] = useState("");
  const [userData, setuserData] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    var data = JSON.parse(localStorage.getItem("PartnerDetails"));
    setPartnerData(data);
    var data = JSON.parse(localStorage.getItem("UsedData"));
    setuserData(data);
  }, []);

  const SendRequest = () => {
     var data = { amount: amount };

    //let fd = new FormData();

   // fd.append("amount", amount); // multiple upload
    HttpCallPostTokenForm(
      SendWithdrawAmount,
      POST,
      data,
      localStorage.getItem("Token")
    )
      .then((res) => {
        console.log("response 71 71", res);

        toast.success("Request Send Successfully", {
          position: "top-right",
        });
        // setDraftOrderId(res.data.id);
        setLoader(false);
      })
      .catch((err) => {
        console.warn("hiiiiiiiii", err.response.data.message);
        toast.error(err.response.data.message, {
          position: "top-right",
        });

        setLoader(false);
      });
  };
  return (
    <>
      <div>
        <span
          style={{ padding: "5px 0", display: "flex", flexDirection: "column" }}
        >
          <label style={{ fontSize: "14px" }}>
            Minimum Amount: {userData.currency +' '+ 1000}
          </label>
          <label style={{ fontSize: "14px" }}>
            Maximum Amount: {userData.currency +' '+10000}
          </label>
        </span>
        <FormGroup>
          <FormText color="muted">Amount to be Withdraw</FormText>
          <Input
            type="number"
            min="0"
            name="amount"
            placeholder="enter Amount.."
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            required
            //readOnly={draftOrderId}
          />
          <div className="text-center">
            <Button
              className="my-3"
              color="primary"
              //type="submit"
              onClick={SendRequest}
              disabled={loader}
            >
              {loader == true ? <ButtonLoader /> : "Send Request"}
            </Button>
          </div>
        </FormGroup>
      </div>
    </>
  );
}
