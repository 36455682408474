import { HttpCallPost } from "Apis";
import { HttpCallPostToken } from "Apis";
import { ButtonLoader } from "components/Loaders/Loader";
import { GenerateActivationAmount } from "network";
import { POST } from "network";
import { GenerateOrder } from "network";
import { AddProductsPriority } from "network";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Button, Col, FormText, Input, Row, Table } from "reactstrap";

export default function OrderReview(props) {
  const [products, setProducts] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [priorityLoader, setPriorityLoader] = useState(false);
  const [generateAmountLoader, setGenerateAmountLoader] = useState(false);
  const [OrderLoader, setOrderLoader] = useState(false);
  const [installmentOptions, setInstallmentOptions] = useState([]);

  const [installment, setInstallment] = useState(false);
  const [installmentvalue, setInstallmentvalue] = useState("");
  const [activationProductList, setActivationProductList] = useState([]);
  const [isGenerateOrder, setGenerateOrder] = useState(false);
  let history = useHistory();


 // var installmentOptions = [];

  useEffect(() => {
    setProductsList(props.productsDetail);
    var dataList = props.productsDetail?.products?.map((i, index) => {
      return {
        id: i.id,
        priority: index + 1,
      };
    });
    console.log(dataList);
    setProducts(dataList);
    for (var j = 1; j <= props.productsDetail?.products?.length; j++) {
      installmentOptions.push({
          "value": j, "label": j
      })
  } 
  console.log(installmentOptions)
  }, []);
  const CreateOrder = () => {
    setOrderLoader(true)
   let CreateOrder= localStorage.getItem("mode")=='demo'? HttpCallPost(
    GenerateOrder + props.orderId,
    POST
  ): HttpCallPostToken(
    GenerateOrder + props.orderId,
    POST,
    '',
    localStorage.getItem("Token")||''
  )
  CreateOrder.then((res) => {
        console.log("response 71 71", res.data);
        toast.success("Order Generated Sucessfully Successfully", {
          position: "top-right",
        });
        history.push("/admin/order-list");

       // setActivationProductList(res.data.products);
      //  setReadOnly(true);
       // setInstallment(true);
         setOrderLoader(false);
      })
      .catch((err) => {
        console.warn("hiiiiiiiii", err.response.data.message);
        toast.error(err.response.data.message, {
          position: "top-right",
        });
        setOrderLoader(false);
      });
  };
  const AddPriority = () => {
    console.log(products);
    setPriorityLoader(true);
    var data = { products: products };
    HttpCallPostToken(
      AddProductsPriority + props.orderId,
      POST,
      data,
      localStorage.getItem("Token")
    )
      .then((res) => {
        console.log("response 71 71", res.data.products);
        toast.success("Priorities Added Successfully", {
          position: "top-right",
        });
        setActivationProductList(res.data.products);
        setReadOnly(true);
        setInstallment(true);
       setPriorityLoader(false);
      })
      .catch((err) => {
        console.warn("hiiiiiiiii", err.response.data.msg);
        toast.error(err.response.data.msg, {
          position: "top-right",
        });
         setPriorityLoader(false);
      });
  };
  const GenerateAmount = () => {
    console.log(activationProductList);
    var prodctList = activationProductList.map((i, index) => {
      return {
        id: i.id,
        priority: i.priority,
        price:i?.pricing?.price
      };
    });
    console.log(prodctList);
    setGenerateAmountLoader(true);
    var data = {
      products: prodctList,
      installments: installmentvalue,
    };
    HttpCallPostToken(
      GenerateActivationAmount + props.orderId,
      POST,
      data,
      localStorage.getItem("Token")
    )
      .then((res) => {
        console.log("response 71 71", res.data.products);
        toast.success("Priorities Added Successfully", {
          position: "top-right",
        });
        setProductsList(res.data);
        setGenerateOrder(true);
        //setInstallment(true);
         setGenerateAmountLoader(false);
      })
      .catch((err) => {
        console.warn("hiiiiiiiii", err.response.data.msg);
        toast.error(err.response.data.message, {
          position: "top-right",
        });
         setGenerateAmountLoader(false);
      });
  };
  return (
    <>
      <>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Services</th>
              <th scope="col">Price</th>
              <th scope="col">Priority</th>
              {isGenerateOrder==true?(<th scope="col">Activation Amount</th>):("")} 

              <th scope="col" />
            </tr>
          </thead>

          {productsList?.products?.map((i, index) => (
            <tbody>
              <tr>
                <th scope="row">{index + 1}</th>
                <td>{i?.name}</td>
                <td>{i.pricing?.price}</td>
                <td>
                  <Input
                    className="my-2"
                    type="number"
                    placeholder="Enter Code..."
                    defaultValue={index + 1}
                    onChange={(e) => {
                      let temp_state = [...products];
                      let temp_element = { ...temp_state[index] };
                      temp_element.priority = e.target.value;
                      temp_state[index] = temp_element;
                      setProducts(temp_state);
                    }}
                    readOnly={readOnly}
                  />
                </td>
                {isGenerateOrder==true?(<td>{i?.activationAmount}</td>):("")} 

              </tr>
            </tbody>
          ))}
        </Table>
        {installment == false ? (
          <>
            <Col xs="12" md="12" style={{ textAlign: "center" }}>
              Total Amount:
              {`${productsList?.currency} ${productsList?.pricing?.netAmount}`}
            </Col>
            <Row>
              {" "}
              <Col xs="6" md="6" style={{ textAlign: "center" }}>
                <Button
                  className="my-2"
                  color="primary"
                  type="submit"
                  //disabled={loader}
                  onClick={() => {
                    props.setShowReview(false);
                    props.setReviewVisible(false);
                  }}
                >
                  Previous
                </Button>
              </Col>
              <Col xs="6" md="6" style={{ textAlign: "center" }}>
                <Button
                  className="my-2"
                  color="primary"
                  type="submit"
                  disabled={priorityLoader}
                  onClick={() => {
                    AddPriority();
                  }}
                >
                 {priorityLoader == true ? <ButtonLoader /> : "Next"} 
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col xs="6" md="6" style={{ textAlign: "center" }}>
              <FormText color="muted">Select Installment</FormText>

                <Select
                  value={installmentOptions.label}
                  name="customerID"
                  onChange={(e) => {
                    setInstallmentvalue(e.value);
                  }}
                  options={installmentOptions}
                />
              </Col>
              <Col xs="6" md="6" style={{ textAlign: "center" }}>
                <Button
                  className="my-2"
                  color="primary"
                  type="submit"
                  disabled={generateAmountLoader}
                  onClick={() => {
                    GenerateAmount();
                  }}
                >
                 
                 {generateAmountLoader == true ? <ButtonLoader /> : "Generate Activation Amount"}  
                </Button>
              </Col>
            </Row>
           { isGenerateOrder == true?(
            <Row>
              <Col xs="12" md="12" style={{ textAlign: "center" }}>
              Total Amount:
              {`${productsList?.currency} ${productsList?.pricing?.netAmount}`}  </Col>
              <Col xs="12" md="12" style={{ textAlign: "center" }}>
              <Button
                  className="my-2"
                  color="primary"
                  type="submit"
                  disabled={OrderLoader}
                  onClick={() => {
                    CreateOrder();
                  }}
                >
                 {OrderLoader == true ? <ButtonLoader /> : "Generate Order"}  
                </Button>
              </Col>
            </Row>):("")}
          </>
        )}
      </>
    </>
  );
}
