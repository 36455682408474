/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// export NODE_OPTIONS=--openssl-legacy-provider
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { Store } from './redux';

let renderedComponent;
if (localStorage.getItem("Token")) {
  console.log("demomode");
  renderedComponent = <Redirect from="/" to="/admin" />
} else {
  renderedComponent = <Redirect from="/" to="/auth/login" />
}

ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>

      <Toaster />
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        {renderedComponent}
      </Switch>
    </BrowserRouter></Provider>,
  document.getElementById("root")
);
