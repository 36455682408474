import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  Card,
  CardHeader,
  Container,
  Row,
  FormGroup,
  Input,
  Button,
  Col,
  FormText,
} from "reactstrap";
import * as yup from "yup";
import Select from "react-select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { HttpCallGet } from "Apis";
import { GET, POST } from "network";
import { customerList } from "network";
import { HttpCallPost, HttpCallPostToken } from "../../Apis/index";
import { createDraftOrder } from "network";
import AddProductForOrder from "./AddProductForOrder";
import { ContainerLoader } from "components/Loaders/Loader";
import { ButtonLoader } from "components/Loaders/Loader";

export default function CreateDraftOrder() {
  const [customerListData, setCustomerListData] = useState("");
  const [isGstDetail, setGstDetail] = useState(false);
  const [draftOrderId, setDraftOrderId] = useState("");
  const [loader, setLoader] = useState(false);

  const InitialData = {
    customerID: "",
    type: "",
    direction: "",
    builtUpArea: "",
    width: "",
    floors: "",
    length: "",
    isGst: "",
    gstNo: "",
    businessName: "",
  };
  const validationSchema = yup.object({
    customerID: yup.string().required("Required"),
  });
  useEffect(() => Data(), []);
  const Data = async () => {
    HttpCallGet(customerList, GET, localStorage.getItem("Token"))
      .then((res) => {
        let customer = res?.data?.userDetails ||res?.data?.customers
        setCustomerListData(
          customer.map((data) => ({
            label:
              data?.firstname !== null
                ? `${data?.firstname} ${data?.lastname}(${"+" + data?.isdCode + data?.contact})`
                : `${"+" + data?.isdCode + data?.contact}`,
            value: data.userID,
          }))
        );
      })
      .catch((err) => {
        console.log("error in table", err);
        toast.error("Error!! ", err.data.msg, {
          position: "top-right",
        });
      });
  };

  const TypeOptions = [
    { label: "Residential", value: "RESD" },
    { label: "Commercial", value: "COMM" },
  ];
  const DirectionOptions = [
    { label: "North", value: "N" },
    { label: "South", value: "S" },
    { label: "East", value: "E" },
    { label: "West", value: "W" },
    { label: "North-East", value: "NE" },
    { label: "North-West", value: "NW" },
    { label: "South-East", value: "SE" },
    { label: "South-West", value: "SW" },
  ];
  const CreateDraftOrder = (values) => {
    setLoader(true);
    console.log(values);
    HttpCallPostToken(
      createDraftOrder,
      POST,
      values,
      localStorage.getItem("Token")
    )
      .then((res) => {
        console.log("response 71 71", res);

        toast.success("Order Created Successfully", {
          position: "top-right",
        });
        setDraftOrderId(res.data.id);
        setLoader(false);
      })
      .catch((err) => {
        console.warn("hiiiiiiiii", err.response.data.msg);
        toast.error(err.response.data.msg, {
          position: "top-right",
        });

        setLoader(false);
      });
  };
  return (
    <>
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Create Draft Order</h3>
              </CardHeader>
              {loader == true ? (
                <ContainerLoader />
              ) : (
                ""
              )}
              <Container
                fluid
                style={{ position: "relative" }}
                disabled={draftOrderId}
              >
                <Formik
                  initialValues={InitialData}
                  validationSchema={validationSchema}
                  // onSubmit={(values) => {
                  //   console.log("submit!");
                  //   CreateDraftOrder(values)}}
                >
                  {({ isSubmitting, values, setFieldValue,handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <Field name="name">
                        {({ field, form }) => (
                          <>
                            <FormGroup row>
                              <Col xs="12" md="4">
                                <FormText color="muted">Customer Name</FormText>
                                <Select
                                  value={customerListData.label}
                                  name="customerID"
                                  onChange={(e) => {
                                    console.log("customerListData", customerListData)
                                     setFieldValue("customerID", e.value);
                                  }}
                                  options={customerListData}
                                  isDisabled={draftOrderId}
                                />
                                <ErrorMessage
                                  name="customerID"
                                  style={{
                                    color: "red",
                                    fontWeight: "600",
                                    fontSize: "12px",
                                  }}
                                  component={"div"}
                                />
                              </Col>
                              <Col xs="12" md="4">
                                <FormText color="muted">Type</FormText>
                                <Select
                                  name="type"
                                  value={TypeOptions.label}
                                  onChange={(e) => {
                                    setFieldValue("type", e.value);
                                  }}
                                  options={TypeOptions}
                                  isDisabled={draftOrderId}

                                />
                              </Col>
                              <Col xs="12" md="4">
                                <FormText color="muted">Direction</FormText>
                                <Select
                                  name="direction"
                                  value={DirectionOptions.label}
                                  onChange={(e) => {
                                    setFieldValue("direction", e.value);
                                  }}
                                  options={DirectionOptions}
                                  isDisabled={draftOrderId}

                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Col xs="12" md="3">
                                <FormText color="muted">Width</FormText>
                                <Input
                                  type="number"
                                  min="0"
                                  name="width"
                                  placeholder="width"
                                  value={values.width}
                                  onChange={(e) => {
                                    setFieldValue("width", e.target.value);
                                    setFieldValue(
                                      "builtUpArea",
                                      e.target.value * values?.length * values?.floors
                                    );
                                  }}
                                  required
                                  readOnly={draftOrderId}
                                />
                              </Col>
                              <Col xs="12" md="3">
                                <FormText color="muted">Length</FormText>
                                <Input
                                  type="number"
                                  min="0"
                                  name="length"
                                  placeholder="length"
                                  value={values.length}
                                  onChange={(e) => {
                                    setFieldValue("length", e.target.value);
                                    setFieldValue(
                                      "builtUpArea",
                                      values?.width * e.target.value * values?.floors
                                    );
                                  }}
                                  required
                                  readOnly={draftOrderId}

                                />
                              </Col>
                              <Col xs="12" md="3">
                                <FormText color="muted">
                                  Number of Floor
                                </FormText>
                                <Input
                                  type="number"
                                  min="0"
                                  name="floors"
                                  placeholder="floors"
                                  value={values.floors}
                                  onChange={(e) =>
                                   { setFieldValue("floors", e.target.value);
                                   setFieldValue(
                                    "builtUpArea",
                                    values?.width * e.target.value * values?.length
                                  );
                                    }
                                  }
                                  required
                                  readOnly={draftOrderId}

                                />
                              </Col>
                              <Col xs="12" md="3">
                                <FormText color="muted">Build Up Area</FormText>
                                <Input
                                  type="number"
                                  min="0"
                                  name="builtUpArea"
                                  placeholder="Area"
                                  value={values.builtUpArea}
                                  onChange={(e) =>
                                    setFieldValue("builtUpArea", e.target.value)
                                  }
                                  readOnly={draftOrderId}

                                />
                              </Col>
                            </FormGroup>{" "}
                            <FormGroup row style={{ padding: "0 18px" }}>
                              <Col xs="12" md="12">
                                <Input
                                  style={{
                                    marginLeft: "-15px",
                                    marginTop: "7px",
                                  }}
                                  type="checkbox"
                                  name="isGst"
                                  checked={isGstDetail}
                                  onChange={() => {
                                    setGstDetail(!isGstDetail);
                                    setFieldValue("isGst", !isGstDetail);
                                    if (!isGstDetail == false) {
                                      setFieldValue("gstNo", "");
                                      setFieldValue("businessName", "");
                                    }
                                  }}
                                  readOnly={draftOrderId}

                                />{" "}
                                <FormText color="muted">
                                  Do you want to add GST details of customer?
                                </FormText>
                              </Col>
                            </FormGroup>
                            {isGstDetail == true ? (
                              <>
                                <FormGroup row>
                                  <Col xs="12" md="6">
                                    <FormText color="muted">
                                      GST Number
                                    </FormText>
                                    <Input
                                      type="text"
                                      name="gstNo"
                                      placeholder="GST No"
                                      value={values.gstNo}
                                      onChange={(e) =>
                                        setFieldValue("gstNo", e.target.value)
                                      }
                                      readOnly={draftOrderId}

                                    />
                                  </Col>
                                  <Col xs="12" md="6">
                                    <FormText color="muted">
                                      Business Name
                                    </FormText>
                                    <Input
                                      type="text"
                                      name="businessName"
                                      placeholder="Business Name"
                                      value={values.businessName}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "businessName",
                                          e.target.value
                                        )
                                      }
                                      readOnly={draftOrderId}

                                    />
                                  </Col>
                                </FormGroup>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </Field>
                      {!draftOrderId && (
                        <div className="text-right">
                          <Button
                            className="my-4"
                            color="primary"
                            type="submit"
                             disabled={loader}
                            onClick={()=>{
                              if(values.customerID !== '')
                              { CreateDraftOrder(values);}
                              console.log(values)}}
                          >
                            {loader == true ? <ButtonLoader /> : "Submit"}
                          </Button>
                        </div>
                      )}
                    </Form>
                  )}
                </Formik>
              </Container>
            </Card>
          </div>
        </Row>
      </Container>
      {draftOrderId && (
        <Container className="pt-3" fluid>
          <AddProductForOrder draftOrderId={draftOrderId} />
        </Container>
      )}
    </>
  );
}
