import { 
    mmh
} from "../AxiosInstance";

const api = (
    // type = 'MMH',
    method,
    endPoint,
    data = ''
) => {

    let instance =  mmh
    let configuration = {
        method : method,
        url : endPoint,
        data : data,
    }
    return instance(configuration)
}

// export const authorization = () => {
//     return api("MMH","GET",'internal/Me')
// }

export default api;