
import {
  Card,
  CardBody,
  Row,
  Col,
  Container,
} from "reactstrap";
import AddCustomerModel from "./AddCustomerModel";


const AddCustomer = () => {
  return (
    <>
      <Container className="mt--9" fluid>
        <div className="header-body text-center mb-7">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary shadow border-0">
                {/* <CardHeader className="bg-transparent pb-5"> */}
                <div className="text-muted text-center mt-2 mb-3">
                  <small>
                    Add Customer
                    {/* with */}
                  </small>
                </div>

                <CardBody className="px-lg-5 py-lg-5">
                  {/* <div className="text-center text-muted mb-4">
            <small>Or sign in with credentials</small>
          </div> */}
                 <AddCustomerModel/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default AddCustomer;
